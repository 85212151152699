import React from "react";
import {FormattedMessage, injectIntl, Link} from "gatsby-plugin-react-intl";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Ribbon from "../misc/ribbon";

class PricingPlans extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedCurrency: "eur",
            startupPlans: props.plans[0],
            selectedStartupPlan: props.plans[0].planOptions[0],
            onlinePlans: props.plans[1],
            selectedOnlinePlan: props.plans[1].planOptions[0],
            enterprisePlans: props.plans[2],
            selectedEnterprisePlan: props.plans[2].planOptions[0],
        };
    }

    componentDidMount(){
        if (typeof window !== 'undefined' && !localStorage.getItem("notificareSelectedCurrency")) {
            localStorage.setItem("notificareSelectedCurrency", "eur");
        }
        this.setState({
            selectedCurrency: localStorage.getItem("notificareSelectedCurrency")
        });
    }

    handleCurrencyChange = (e) => {
        this.setState({
            selectedCurrency: e.target.value
        });
        localStorage.setItem("notificareSelectedCurrency", e.target.value);
    }

    handleStartupPlanChange = (e) => {
        this.props.plans[0].planOptions.forEach((plan) => {
            if (e.target.value === plan.key) {
                this.setState({
                    selectedStartupPlan: plan
                });
            }
        });
    }

    handleOnlinePlanChange = (e) => {
        this.props.plans[1].planOptions.forEach((plan) => {
            if (e.target.value === plan.key) {
                this.setState({
                    selectedOnlinePlan: plan
                });
            }
        });
    }

    render() {
        return (
            <div className={`pricing-plans`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={`currency-selector`}>
                                {this.props.currencies.map((currency, i) => {
                                    return(
                                        <div key={i} className={`radio-group inline`}>
                                            <input id={`currency-` + currency.key} type={`radio`} name={`currency`} value={currency.key} checked={currency.key === this.state.selectedCurrency ? true : false} onChange={this.handleCurrencyChange} />
                                            <label htmlFor={`currency-` + currency.key}>{currency.value}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg>
                            <div className={`plans-col-box`}>
                                <div className={`plan-main-info`}>
                                    <div className={`title`}>
                                        {this.state.onlinePlans.title}
                                    </div>
                                    <div className={`plan-options`}>
                                        <div className={`plan-options-selector`}>
                                            <select value={this.state.selectedOnlinePlan.key} onChange={this.handleOnlinePlanChange} onBlur={this.handleOnlinePlanChange}>
                                                {this.state.onlinePlans.planOptions.map((pOption, i) => {
                                                    return (
                                                        <option key={i} value={pOption.key}>
                                                            {pOption.users}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={`text`}>
                                        {this.state.onlinePlans.text}
                                    </div>
                                    <div className={`price`}>
                                        <span
                                          className={`currency-symbol`}>{this.state.selectedCurrency === "eur" ? "€" : "$"}</span>{this.state.selectedOnlinePlan.price[this.state.selectedCurrency]}<span
                                      className={`periodicity`}><FormattedMessage id="shared.planPeriodicity"/></span>
                                        <div className={`billed-annually`}><FormattedMessage id="shared.billedAnnually"/></div>
                                        <div className={`excl-vat`}><FormattedMessage id="shared.exclVat"/></div>
                                    </div>
                                </div>
                                <div className={`plan-features`}>
                                    {this.state.selectedOnlinePlan.features.map((feature, i) => {
                                        return (
                                            <div key={i} className={`feature`}><FontAwesomeIcon icon="check" />{feature}</div>
                                        )
                                    })}
                                </div>
                                <div className={`plan-cta`}>
                                    <Link to={this.state.selectedOnlinePlan.button.url} className={`btn btn-lg btn-secondary btn-block`}>{this.state.selectedOnlinePlan.button.label}</Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg>
                            <div className={`plans-col-box`}>
                                <Ribbon theme={`secondary`} orientation={`left`} text={this.props.intl.formatMessage({id: `shared.bestValue`})} />
                                <div className={`plan-main-info`}>
                                    <div className={`title`}>
                                        {this.state.enterprisePlans.title}
                                    </div>
                                    <div className={`plan-options read-only`}>
                                        {this.state.selectedEnterprisePlan.users}
                                    </div>
                                    <div className={`text`}>
                                        {this.state.enterprisePlans.text}
                                    </div>
                                    {/*<div className={`price`}>*/}
                                    {/*   {this.state.selectedEnterprisePlan.price[this.state.selectedCurrency]}*/}
                                    {/*</div>*/}
                                </div>
                                <div className={`plan-features`}>
                                    {this.state.selectedEnterprisePlan.features.map((feature, i) => {
                                        return (
                                            <div key={i} className={`feature`}><FontAwesomeIcon icon="check" />{feature}</div>
                                        )
                                    })}
                                </div>
                                <div className={`plan-cta`}>
                                    <Link to={this.state.selectedEnterprisePlan.button.url} className={`btn btn-lg btn-secondary btn-block`}>{this.state.selectedEnterprisePlan.button.label}</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default injectIntl(PricingPlans);
